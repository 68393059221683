<template>
  <!--begin::PriceSearchPurchaseKO-->
  <div class="text-center py-12">
    <h2 class="text-danger my-12">
      <inline-svg class="mr-3" src="/media/icons/cross-danger.svg" />
      {{ $t("PRICE_SEARCH.PURCHASE_KO.TITLE") }}
    </h2>

    <div class="text-center my-12">
      <button
        class="btn btn-pill btn-white button-form button-form-primary"
        @click="onClickBackButton"
      >
        <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
        {{ $t("GENERIC.BACK") }}
      </button>
    </div>
  </div>
  <!--end::PriceSearchPurchaseKO-->
</template>

<script>
export default {
  name: "PriceSearchPurchaseKO",

  methods: {
    onClickBackButton() {
      this.$router.push("/pricesearch/step1");
    }
  }
};
</script>
